<template>
    <CRow>
        <CCol sm="8" v-if="user_info">

            <CCard>
                <CCardHeader>
                    Edit your profile
                </CCardHeader>
                <CCardBody>
                    <CForm>
                        <CCol sm="8">
                            <CInput
                                    label="First Name"
                                    plaintext
                                    horizontal
                                    :value.sync="user_info.usr_firstname"
                            />
                        </CCol>
                        <CCol sm="8">
                            <CInput
                                    label="Last Name"
                                    horizontal
                                    plaintext
                                    :value.sync="user_info.usr_lastname"
                            />
                        </CCol>
                        <CCol sm="8">
                            <CInput
                                    label="Email"
                                    plaintext
                                    horizontal
                                    :value.sync="user_info.usr_email"
                            />
                        </CCol>
                        <hr>
                        <CRow>
                            <CCol sm="6">
                                <CInput
                                        label="New Password"
                                        horizontal
                                        :type="passtype"
                                        :value.sync="new_pass"
                                />
                            </CCol>
                            <CCol sm="2">
                                <CBadge pill @click="showPW" color="danger">
                                    eye
                                </CBadge>
                            </CCol>
                            <CCol sm="4">


                                <p v-html="tooltip"></p>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="6">
                                <CInput
                                        label="Confirm Password"
                                        horizontal
                                        :type="passtypec"
                                        :value.sync="mew_pass_confirm"
                                />
                            </CCol>
                            <CCol sm="2">
                                <CBadge pill @click="showPWC" color="danger">
                                    eye
                                </CBadge>
                            </CCol>
                        </CRow>
                    </CForm>
                </CCardBody>
                <CCardFooter>
                    <CButton v-if="mew_pass_confirm != '' && new_pass != ''" :disabled="disabledUpdate" pill color="primary" @click="updateInfo">

                        Update
                    </CButton>
                </CCardFooter>
            </CCard>
        </CCol>

    </CRow>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'AdminSettings',
        data() {
            return {
                user_info: null,
                new_pass: "",
                passtype: "password",
                passtypec: "password",
                mew_pass_confirm: "",
                disabledUpdate: false,
                tooltip: "<li>Ensure string has two uppercase letters.</li>" +
                    "<li>Ensure string has atleast one special case letter.</li>" +
                    "<li>Ensure string has atleast two digits.</li>" +
                    "<li>Ensure string has atleast three lowercase letters.</li>" +
                    "<li>Ensure string is of min. length of 8.</li>"

            }
        },
        methods: {
            validatePass() {
                const pw_regex = RegExp("^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&*()\\-__+.]){1,}).{8,}$")

                return pw_regex.test(this.new_pass);
            },
            showPW() {
                if (this.passtype === "password") {
                    this.passtype = "text";
                } else {
                    this.passtype = "password";
                }
            },
            showPWC() {
                if (this.passtypec === "password") {
                    this.passtypec = "text";
                } else {
                    this.passtypec = "password";
                }
            },
            getInfo() {
                axios.get(this.$baseURL + '/admin',
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }
                ).then((res) => {
                    this.user_info = res.data;
                }).catch((error) => {
                    this.$toast.error(error);
                })
            },
            updateInfo() {
                if (this.new_pass != this.mew_pass_confirm) {
                    this.$toast.error("Password doesn't match")
                    return
                }
                var valid = this.validatePass();

                if (valid) {
                    this.disabledUpdate = true;
                    axios.put(this.$baseURL + '/admin', {
                            usr_firstname: this.user_info.usr_firstname,
                            usr_lastname: this.user_info.usr_lastname,
                            usr_email: this.user_info.usr_email,
                            usr_password: this.new_pass
                        },
                        {
                            headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                            // eslint-disable-next-line no-unused-vars
                        }).then((res) => {
                        this.$toast.success("Profile updated successfully");
                        this.disabledUpdate = false;
                        this.getInfo();

                    }).catch((error) => {
                        this.$toast.error(error.response.data.detail);
                    })

                } else {
                    this.disabledUpdate = false;
                    this.$toast.error("Password doesn't meet requirements!!!")

                }

            }

        },
        mounted() {
            this.getInfo();
        },
        computed: {}
    }
</script>


<style scoped>
</style>